import React, { useMemo, useState } from "react";
import { Box, Heading, Paragraph, ResponsiveContext, Skeleton, Text } from "grommet";
import styled from "styled-components";
import { useSelector } from "react-redux";

import { RootState } from "../store";
import { VulnerablePopulationMetricType } from "../models";
import {
  setIsWidgetInitialState,
  setSelectedVulnerablePopulations
} from "../actions/vulnerablePopulations";
import Pill from "./VulnerablePopWidgetPill";
import PlusIconSmall from "../icons/PlusIconSmall";
import { useDispatch } from "react-redux";

const Message = styled(Paragraph)`
  background: var(--warm-gray-200);
  padding: 0.6rem 0.9rem;
  border-radius: var(--border-radius);
  font-size: 1.4rem;

  span {
    font-size: inherit;
  }
`;

export type PreviousTerm = {
  term: string | null;
  position: DOMRect | null;
};

const VulnerablePopWidgetExpanded = () => {
  const {
    selectedVulnerablePopulations,
    rankedVulnerablePopulations,
    selectedHighlightedTractIds,
    isWidgetInitialState
  } = useSelector((state: RootState) => state.vulnerablePopulations);
  const [previousTerm, setPreviousTerm] = useState<PreviousTerm>({ term: null, position: null });
  const [terms, setTerms] = useState<ReadonlyArray<VulnerablePopulationMetricType>>([]);

  const dispatch = useDispatch();

  // Populate terms list from vulnerable populations state
  useMemo(() => {
    rankedVulnerablePopulations.length > 0 &&
      setTerms(rankedVulnerablePopulations as ReadonlyArray<VulnerablePopulationMetricType>);
  }, [rankedVulnerablePopulations]);

  const setSelectedPopulations = (populations: ReadonlyArray<string>) => {
    dispatch(setSelectedVulnerablePopulations(populations));
  };

  const handleSelect = (newValue: VulnerablePopulationMetricType) => {
    selectedVulnerablePopulations.includes(newValue)
      ? setSelectedPopulations(selectedVulnerablePopulations.filter(val => val !== newValue))
      : setSelectedPopulations([...selectedVulnerablePopulations, newValue]);
    // Move the term that was just toggled to the end of the array
    setTerms([...terms.filter(term => term !== newValue), newValue]);
    dispatch(setIsWidgetInitialState(false));
  };

  return (
    <ResponsiveContext.Consumer>
      {size => (
        <Box>
          <Box
            pad="small"
            gap="small"
            role="group"
            a11yTitle="Highlighted categories"
            style={{ borderBottom: size === "small" ? undefined : "1px solid var(--warm-gray-300" }}
          >
            <Heading id="desc-highlighted" level={4}>
              Highlight areas that rank above the local median for:
            </Heading>
            {terms.length !== 0 ? (
              <Box
                direction="row"
                wrap={true}
                style={{
                  gap: ".5rem .25rem"
                }}
                align="center"
              >
                {terms
                  .filter(term => selectedVulnerablePopulations.includes(term))
                  // Inserts a "+" icon after each pill
                  .flatMap((term, index) => [
                    <Pill
                      term={term}
                      key={term}
                      selected={true}
                      previousTerm={previousTerm}
                      setPreviousTerm={setPreviousTerm}
                      handleClick={handleSelect}
                    />,
                    <PlusIconSmall key={index} size="11" color="var(--text-700)" aria-label="and" />
                  ])
                  // Remove the trailing "+"
                  .slice(0, -1)}
              </Box>
            ) : (
              <Skeleton height="2.6rem" />
            )}
            {rankedVulnerablePopulations.length > 0 ? (
              selectedVulnerablePopulations.length === 0 ? (
                <Message role="status">Select one or more categories to see results</Message>
              ) : (
                selectedHighlightedTractIds.length === 0 && (
                  <Message role="status">
                    <Text weight={600} color="black" style={{ display: "block" }}>
                      No areas meet these criteria
                    </Text>
                    Select fewer categories to see results
                  </Message>
                )
              )
            ) : (
              ""
            )}
          </Box>
          <Box pad="small" gap="small" role="group" aria-labelledby="categories">
            <Heading level={4} id="categories">
              Other categories
            </Heading>
            <Box direction="row" wrap={true} style={{ gap: ".5rem" }}>
              {terms.length !== 0 ? (
                terms
                  .filter(term => !selectedVulnerablePopulations.includes(term))
                  .map((term, index) => (
                    <Pill
                      term={term}
                      key={term}
                      previousTerm={previousTerm}
                      setPreviousTerm={setPreviousTerm}
                      handleClick={handleSelect}
                    />
                  ))
              ) : (
                <Skeleton height="8.8rem" />
              )}
            </Box>
            {/* In the initial state, show text explaining the selection criteria */}
            {isWidgetInitialState && (
              <Paragraph size="small">Two highest-ranked categories selected by default.</Paragraph>
            )}
          </Box>
        </Box>
      )}
    </ResponsiveContext.Consumer>
  );
};

export default VulnerablePopWidgetExpanded;
