import React from "react";
import { Box, ResponsiveContext, Heading, Paragraph } from "grommet";
import { Screen, GeographyLevel, ComparisonLevel, Ranks, Geography } from "../models";
import { VulnerablePopulationsState } from "../reducers/vulnerablePopulations";
import { RRZState } from "../reducers/riskReductionZone";
import VulnerablePopulationsComparisonText from "./VulnerablePopulationsComparisonText";
import WildfireLikelihoodComparisonText from "./WildfireLikelihoodComparisonText";
import RRZComparisonText from "./RRZComparisonText";
import RiskToHomesComparisonText from "./RiskToHomesComparisonText";
import RiskIcon from "./RiskIcon";
import HowTo from "./HowTo";

interface Props {
  readonly screen: Screen;
  readonly detailGeography: Geography | undefined;
  readonly detailGeographyId: string;
  readonly vulnerablePopulations: VulnerablePopulationsState;
  readonly geographyLevel: GeographyLevel;
  readonly comparisonCountyName: string;
  readonly comparisonStateName: string;
  readonly comparisonLevel: ComparisonLevel;
  readonly rrz: RRZState;
  readonly ranks: Ranks | undefined;
}

const RiskDetailHeader = (props: Props) => {
  const {
    screen,
    detailGeography,
    detailGeographyId,
    geographyLevel,
    vulnerablePopulations,
    comparisonCountyName,
    comparisonStateName,
    comparisonLevel,
    rrz,
    ranks
  } = props;

  const geography = detailGeography ? detailGeography.name_short : "";

  const { heading, riskLevel, definition } =
    screen === Screen.WildfireLikelihood
      ? {
          heading: "Wildfire Likelihood",
          riskLevel: detailGeography?.wildfire_likelihood_class,
          definition:
            "Wildfire likelihood is the probability of wildfire burning in any given year."
        }
      : screen === Screen.RiskReductionZones
      ? {
          heading: "Risk Reduction Zones",
          riskLevel: detailGeography?.exposure_type_class,
          definition:
            "Risk Reduction Zones are the areas where mitigation activities will be most effective at protecting homes and other buildings from wildfire."
        }
      : screen === Screen.RiskToHomes
      ? {
          heading: "Risk to Homes",
          riskLevel: detailGeography?.risk_to_homes_class,
          definition:
            "Risk to homes measures the possible consequence of wildfire to homes in all areas of the landscape."
        }
      : screen === Screen.VulnerablePopulations
      ? {
          heading: "Vulnerable Populations",
          riskLevel: detailGeography?.vulnerable_populations_class,
          definition:
            "Social and economic factors can make it more difficult for some people to prepare for, respond to, and recover from wildfire."
        }
      : {
          heading: "",
          riskLevel: undefined,
          definition: ""
        };

  return (
    <ResponsiveContext.Consumer>
      {size => (
        <Box
          direction="column"
          style={{
            gridColumn: size === "large" ? "span 2" : ""
          }}
          gap="small"
        >
          <Heading
            color="heading"
            level={2}
            margin={{ top: "0", bottom: "20px" }}
            size="xxlarge"
            style={{ padding: size === "small" ? "0 1rem" : undefined }}
          >
            {heading}
          </Heading>
          <Box
            background="white"
            pad={{ horizontal: "medium", vertical: "1.5rem" }}
            direction={size === "small" ? "column" : "row"}
            align={size === "small" ? "center" : "start"}
            gap="medium"
            style={{ borderRadius: "var(--border-radius)" }}
          >
            {riskLevel && (
              <RiskIcon riskLevel={riskLevel} size={size === "small" ? 50 : undefined} />
            )}
            <Box gap=".4rem">
              {screen === Screen.WildfireLikelihood ? (
                <WildfireLikelihoodComparisonText
                  geography={geography}
                  geographyLevel={geographyLevel}
                  comparisonCountyName={comparisonCountyName}
                  comparisonStateName={comparisonStateName}
                  comparisonLevel={comparisonLevel}
                  ranks={ranks}
                />
              ) : screen === Screen.RiskReductionZones ? (
                <RRZComparisonText
                  geography={geography}
                  detailGeographyId={detailGeographyId}
                  rrz={rrz}
                />
              ) : screen === Screen.RiskToHomes ? (
                <RiskToHomesComparisonText
                  geography={geography}
                  geographyLevel={geographyLevel}
                  comparisonCountyName={comparisonCountyName}
                  comparisonStateName={comparisonStateName}
                  comparisonLevel={comparisonLevel}
                  ranks={ranks}
                />
              ) : screen === Screen.VulnerablePopulations ? (
                <VulnerablePopulationsComparisonText
                  geography={geography}
                  detailGeographyId={detailGeographyId}
                  vulnerablePopulations={vulnerablePopulations}
                />
              ) : (
                <></>
              )}
              <Paragraph size="large">{definition}</Paragraph>
            </Box>
          </Box>
          <HowTo screen={screen} />
        </Box>
      )}
    </ResponsiveContext.Consumer>
  );
};

export default RiskDetailHeader;
