import React, { useState } from "react";
import { Anchor, Box, Heading, Paragraph } from "grommet";
import styled from "styled-components";
import AngleRightIcon from "../icons/AngleRightIcon";
import PlusIcon from "../icons/PlusIcon";
import MinusIcon from "../icons/MinusIcon";

const content = [
  {
    question: "When was the data last updated?",
    answer: (
      <Paragraph size="large">
        Wildfire risk data were updated in 2024. Data for vulnerable populations and political
        boundaries are updated annually. See our{" "}
        <Anchor href="https://wildfirerisk.org/about/methods/" target="_blank">
          full update schedule
        </Anchor>
        .
      </Paragraph>
    )
  },
  {
    question: "What are the limitations of the data?",
    answer: (
      <>
        <Paragraph size="large">
          Wildfire Risk to Communities uses the best available science to model wildfire risk to
          homes and communities. As a national project, the data are not locally calibrated.
        </Paragraph>
        <Paragraph size="large">
          The data are intended for evaluating risk across a community, rather than at the
          individual home scale. Wildfire Risk to Communities does not address wildfire risk to
          other resources or values like watersheds or landscape health.{" "}
          <Anchor href="https://wildfirerisk.org/about/faq/" target="_blank">
            Read more
          </Anchor>
          .
        </Paragraph>
      </>
    )
  },
  {
    question: "Why does my mitigation project not change the risk?",
    answer: (
      <Paragraph size="large">
        Local mitigation projects may be too small to affect wildfire risk at the scale of Wildfire
        Risk to Communities, or they may have occurred since our data were last updated. The data in
        Wildfire Risk to Communities are available for download so that you can combine them with
        localized information for your own analyses.{" "}
        <Anchor href="https://wildfirerisk.org/about/faq/" target="_blank">
          Read more
        </Anchor>
        .
      </Paragraph>
    )
  }
];

const Details = styled.details`
  width: 100%;
  border-bottom: 1px solid var(--warm-gray-300);
  .minus-icon {
    display: none;
  }
  &[open] {
    padding-bottom: 3rem;
    p + p {
      margin-top: 1rem;
    }
    p a {
      text-decoration: underline;
      text-underline-offset: 0.1em;
      text-decoration-thickness: 1px;
      text-decoration-color: var(--warm-gray-500);
    }
    .plus-icon {
      display: none;
    }
    .minus-icon {
      display: block;
    }
  }
`;

const Summary = styled.summary`
  display: flex;
  font-weight: bold;
  cursor: pointer;
  background: transparent;
  border-radius: 0 !important;
  @media (hover: hover) {
    &:hover {
      background-color: var(--warm-gray-200) !important;
    }
  }
  color: black;
  padding-left: 0 !important;
  padding-right: 1rem !important;
  list-style: none;
  &::-webkit-details-marker {
    display: none;
  }
`;

const FAQ = () => {
  const [openId, setOpenId] = useState<Number | undefined>(undefined);
  const toggle = (event: React.MouseEvent, index: Number, isOpen: Boolean) => {
    event.preventDefault();
    setOpenId(isOpen ? -1 : index);
  };
  return (
    <Box pad={{ horizontal: "medium", bottom: "medium" }} justify="between" className="content-box">
      <Heading level={3}>Frequently asked questions</Heading>
      <Box flex={"grow"}>
        {content.map((item, index) => (
          <Details key={index} open={index === openId}>
            <Summary
              className="button-link large secondary"
              onClick={event => toggle(event, index, index === openId)}
            >
              {item.question}
              <PlusIcon
                size="16px"
                color="var(--warm-gray-500)"
                className="plus-icon"
                aria-hidden
              />
              <MinusIcon
                size="16px"
                color="var(--warm-gray-500)"
                className="minus-icon"
                aria-hidden
              />
            </Summary>
            {item.answer}
          </Details>
        ))}
      </Box>
      <Anchor
        href="https://wildfirerisk.org/about/faq/"
        className="button-link large"
        target="_blank"
      >
        Read more frequently asked questions
        <AngleRightIcon size="14px" color="var(--highlight-700)" aria-hidden />
      </Anchor>
    </Box>
  );
};

export default FAQ;
