import React from "react";
import { Box, Grid, Paragraph, ResponsiveContext, Heading } from "grommet";
import { Screen } from "../models";
import styled from "styled-components";
import PlusIcon from "../icons/PlusIcon";
import MinusIcon from "../icons/MinusIcon";

interface Props {
  readonly screen: Screen;
}

const Details = styled.details`
  width: 100%;
  background: var(--highlight-200);
  border-radius: var(--border-radius);
  .minus-icon {
    display: none;
  }
  &[open] {
    summary {
      border-bottom: 1px solid #b0d9e8;
    }
    .plus-icon {
      display: none;
    }
    .minus-icon {
      display: block;
    }
  }
`;

const Summary = styled.summary<{ size: string }>`
  display: flex;
  padding-left: ${props => (props.size === "small" ? "1rem" : "2rem")} !important;
  cursor: pointer;
  list-style: none;
  &::-webkit-details-marker {
    display: none;
  }
`;

const List = styled.ul`
  margin: 0;
  padding-left: 2rem;
`;

const ListItem = styled.li`
  font-size: 1.8rem;
  line-height: 2.5rem;
  color: var(--text-800);
  margin: 1rem 0;
`;

const HowTo = ({ screen }: Props) => {
  return (
    <ResponsiveContext.Consumer>
      {size => (
        <Details className="restrict-growth-content-container">
          <Summary className="button-link large" size={size}>
            {size === "small"
              ? "Learn how to use this page"
              : "Learn how to use the information on this page"}
            <PlusIcon size="16px" color="var(--highlight-700)" className="plus-icon" aria-hidden />
            <MinusIcon
              size="16px"
              color="var(--highlight-700)"
              className="minus-icon"
              aria-hidden
            />
          </Summary>
          <Grid
            pad="medium"
            gap="large"
            columns={size === "large" ? ["1fr", "1fr"] : ["auto"]}
            rows="auto"
            justify="stretch"
            justifyContent="stretch"
          >
            {screen === Screen.RiskToHomes ? (
              <>
                <Paragraph size="large" style={{ gridColumn: size === "large" ? "span 2" : "" }}>
                  This page can help you see where homes will have the greatest risk of wildfire.
                  The <b>Risk to homes</b> section integrates the likelihood and intensity of
                  wildfire everywhere on the landscape, whether a home exists there or not. This
                  allows you to consider wildfire risk in places where new construction is proposed.
                  Communities in all but the lowest risk class need to be prepared for wildfire.
                </Paragraph>
                <Box>
                  <Heading level={3} size="2.2rem" weight={700}>
                    Use the map to identify priority areas
                  </Heading>
                  <List>
                    <ListItem>
                      Use the map to identify areas in your community that have especially high
                      risk. These are places where mitigation measures may be needed.
                    </ListItem>
                    <ListItem>
                      Switch the <em>Populated areas</em> toggle at the top of the map. This will
                      show where there is already housing that will benefit from ignition-resistant
                      construction and other risk reduction measures. In unpopulated at-risk areas,
                      land use planning strategies can help reduce risk to future development.
                    </ListItem>
                    <ListItem>
                      Visit the <em>Vulnerable populations</em> tab to learn more about the social
                      and economic conditions of the people who live in at-risk neighborhoods.
                    </ListItem>
                  </List>
                </Box>
                <Box>
                  <Heading level={3} size="2.2rem" weight={700}>
                    Use the chart to compare to other places
                  </Heading>
                  <List>
                    <ListItem>
                      Switch <em>Compare to</em> at the top of the page to change the context for
                      the map and chart.
                    </ListItem>
                    <ListItem>
                      Use the chart to compare your location’s risk to other locations.
                    </ListItem>
                    <ListItem>
                      Find peer communities by looking for locations with a similar population size
                      (dot size) or risk level (dot color).
                    </ListItem>
                  </List>
                </Box>
              </>
            ) : screen === Screen.WildfireLikelihood ? (
              <>
                <Paragraph size="large" style={{ gridColumn: size === "large" ? "span 2" : "" }}>
                  This page can help you understand where wildfire is more likely to occur.{" "}
                  <b>Wildfire likelihood</b> measures the relative probability of wildfire burning
                  in any given year. Communities in all but the lowest class need to be prepared for
                  wildfire.
                </Paragraph>
                <Box>
                  <Heading level={3} size="2.2rem" weight={700}>
                    Use the map to identify priority areas
                  </Heading>
                  <List>
                    <ListItem>
                      Use the map to identify areas in your community that have especially high
                      likelihood of wildfire.
                    </ListItem>
                    <ListItem>
                      Switch the <em>Populated areas</em> toggle at the top of the map. This will
                      show where there is already housing and help you visualize where existing
                      homes have a higher probability of experiencing wildfire.
                    </ListItem>
                    <ListItem>
                      Visit the <em>Vulnerable populations</em> tab to learn more about the social
                      and economic conditions of the people who live in at-risk neighborhoods.
                    </ListItem>
                  </List>
                </Box>
                <Box>
                  <Heading level={3} size="2.2rem" weight={700}>
                    Use the chart to compare to other places
                  </Heading>
                  <List>
                    <ListItem>
                      Switch <em>Compare to</em> at the top of the page to change the context for
                      the map and chart.
                    </ListItem>
                    <ListItem>
                      Use the chart to compare your location’s wildfire likelihood to other
                      locations.
                    </ListItem>
                    <ListItem>
                      Find peer communities by looking for locations with a similar population size
                      (dot size) or risk level (dot color).
                    </ListItem>
                  </List>
                </Box>
              </>
            ) : screen === Screen.RiskReductionZones ? (
              <>
                <Paragraph size="large" style={{ gridColumn: size === "large" ? "span 2" : "" }}>
                  This page can help you identify where different mitigation activities will be most
                  effective at protecting homes and other structures from wildfire.
                </Paragraph>
                <Box>
                  <Heading level={3} size="2.2rem" weight={700}>
                    Use the map to identify priority areas
                  </Heading>
                  <List>
                    <ListItem>
                      Use the map to identify where risk reduction activities are needed.
                    </ListItem>
                    <ListItem>
                      Match the most effective risk reduction activities in the <em>Take Action</em>{" "}
                      section with each zone on the map.
                    </ListItem>
                    <ListItem>
                      Visit the <em>Vulnerable populations</em> tab to learn more about the social
                      and economic conditions of the people who live in at-risk neighborhoods.
                    </ListItem>
                  </List>
                </Box>
                <Box>
                  <Heading level={3} size="2.2rem" weight={700}>
                    Use the chart to compare to other places
                  </Heading>
                  <List>
                    <ListItem>
                      Use the chart to identify the share of housing that is directly, indirectly,
                      or minimally exposed to wildfire.
                    </ListItem>
                    <ListItem>
                      Match the most effective risk reduction activities in the <em>Take Action</em>{" "}
                      section with the exposure types in your location.
                    </ListItem>
                  </List>
                </Box>
              </>
            ) : screen === Screen.VulnerablePopulations ? (
              <>
                <Paragraph size="large" style={{ gridColumn: size === "large" ? "span 2" : "" }}>
                  This page can help you identify neighborhoods with people who who may experience
                  disproportionate impacts of wildfire because they lack access to resources,
                  experience cultural and institutional barriers, have limited mobility, and/or have
                  compromised physical health.
                </Paragraph>
                <Box>
                  <Heading level={3} size="2.2rem" weight={700}>
                    Use the map to identify priority neighborhoods
                  </Heading>
                  <List>
                    <ListItem>
                      The map will show the two highest ranking criteria automatically. Toggle
                      different criteria to help make your risk reduction strategies more effective.
                      For example, look only at <em>Limited English</em> to see where you might need
                      to create multilingual outreach materials. Look at <em>Disabilities</em>,{" "}
                      <em>Over 65 years</em>, and <em>No car</em> to see where mobility challenges
                      may affect evacuation planning.
                    </ListItem>
                    <ListItem>
                      Use the drop-down menu to select a <em>Basemap</em> and see where wildfire
                      risk intersects social vulnerability.
                    </ListItem>
                    <ListItem>
                      Click on the map to see detailed information for that neighborhood.
                    </ListItem>
                    <ListItem>
                      Visit the <em>Risk Reduction Zones</em> tab to learn more about what types of
                      risk reduction activities will be most effective.
                    </ListItem>
                  </List>
                </Box>
                <Box>
                  <Heading level={3} size="2.2rem" weight={700}>
                    Use the table to see totals for your location
                  </Heading>
                  <List>
                    <ListItem>
                      Use the table to see the total number and percent of people that meet each
                      criteria in your community.
                    </ListItem>
                    <ListItem>
                      Download a detailed PDF report with more tables, charts, and information about
                      social and economic vulnerability. The report will show how your community
                      compares to the United States as a whole.
                    </ListItem>
                  </List>
                </Box>
              </>
            ) : (
              ""
            )}
          </Grid>
        </Details>
      )}
    </ResponsiveContext.Consumer>
  );
};

export default HowTo;
