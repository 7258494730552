import React from "react";
import { Anchor, Box, Heading, Paragraph } from "grommet";
import styled from "styled-components";
import { ActionDetails } from "../models";
import { logGoogleAnalyticsEvent } from "./GoogleAnalytics";

const ActionCardLink = styled(Anchor)`
  display: flex;
  gap: 0.8rem;
  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
  &:focus-visible {
    outline: none !important;
  }
`;

interface Props {
  readonly highlight?: boolean;
  readonly action: ActionDetails;
  readonly level?: 4 | 5;
  readonly analyticsCategory: string;
}

const ActionCard = ({ highlight, action, level, analyticsCategory }: Props) => {
  const ActionIcon = action.icon;
  return (
    <Box
      as="li"
      className={highlight ? "action-card highlight" : "action-card"}
      pad="small"
      gap="small"
      height={{ min: "7rem" }}
      style={{ position: "relative" }}
      a11yTitle={`
        ${highlight ? "Highlighted: " : ""}
        ${action.name}
      `}
    >
      <Heading
        level={level || 4}
        style={{
          display: "flex",
          alignItems: "center",
          gap: "1rem"
        }}
      >
        <ActionCardLink
          size="medium"
          color={highlight ? "highlight-800" : "text-900"}
          href={action.url}
          onClick={() => {
            logGoogleAnalyticsEvent(analyticsCategory, "action card click", action.name);
          }}
        >
          <ActionIcon size="20px" color="var(--icon-color)" aria-hidden />
          {action.name}
        </ActionCardLink>
      </Heading>
      <Paragraph size="medium">{action.description}</Paragraph>
    </Box>
  );
};

export default ActionCard;
