import { sortedIndex } from "lodash";
import { Box, Heading, Paragraph, ResponsiveContext } from "grommet";
import React, { useMemo } from "react";
import {
  ComparisonLevel,
  GeographyLevel,
  WildfireLikelihood,
  WildfireLikelihoodData,
  WildfireLikelihoodChartData,
  WildfireLikelihoodCollection,
  wildfireLikelihoodColorBreaks,
  wildfireLikelihoodTextBreaks,
  MinimumChartBP
} from "../models";
import { WildfireLikelihoodState } from "../reducers/wildfireLikelihood";
import ChartLegends from "./ChartLegends";
import WildfireLikelihoodChart from "./WildfireLikelihoodChart";
import { getBpSortedValues } from "../utils";
import Delayed from "./Delayed";
import { accessibleChartLabel } from "./accessibleLabels";
import AccessibleDataTable from "./AccessibleDataTable";
import { accessibleDataTableLabel } from "./accessibleLabels";

interface ComponentProps {
  readonly geography: string;
  readonly geographyLevel: GeographyLevel;
  readonly comparisonCountyName: string;
  readonly comparisonStateName: string;
  readonly comparisonLevel: ComparisonLevel;
  readonly detailPlaceId: string;
  readonly wildfireLikelihood: WildfireLikelihoodState;
}

// Match the bubble sizes set by `size` `sizes` in the chart with those in the legend
const minBubbleSize = 6;
const maxBubbleSize = 26;

const getChartData = (
  wildfireLikelihood: WildfireLikelihoodCollection,
  exclude: boolean = false // Whether to exclude places with exclude_from_percentile set.
): WildfireLikelihoodChartData => {
  const bpSortedValues = getBpSortedValues(wildfireLikelihood, exclude);

  const data = Object.entries(wildfireLikelihood)
    .map(([key, w]: readonly [string, WildfireLikelihood]) => {
      const wData = w.d;
      const bp = wData.bp_mean !== undefined ? wData.bp_mean : -1;
      const bpLog = bp > 0 ? Math.log10(bp) : undefined;
      const findColorBreak =
        wildfireLikelihoodColorBreaks.find(b => bp < b.max) ||
        (bp >= wildfireLikelihoodTextBreaks[wildfireLikelihoodTextBreaks.length - 1].max
          ? wildfireLikelihoodTextBreaks[wildfireLikelihoodTextBreaks.length - 1]
          : undefined);
      const colorBreak = findColorBreak
        ? findColorBreak
        : wildfireLikelihoodColorBreaks[wildfireLikelihoodColorBreaks.length - 1];
      const bpSortedIdx = sortedIndex(bpSortedValues, bp);
      const bpPctRank =
        bpSortedIdx > -1
          ? Math.round((bpSortedIdx / (bpSortedValues.length - 1)) * 100) / 100
          : undefined;
      return wData.total_housing_units
        ? Object.assign({ bpLog, bpPctRank, color: colorBreak.value, key, name: wData.name }, wData)
        : undefined;
    })
    .filter(entry => entry !== undefined) as readonly WildfireLikelihoodData[];

  // Extract the full list of values (excluding too-low BP values) to calculate the chart ranges
  const bpLogValues = Object.entries(data)
    .map(([key, w]: readonly [string, WildfireLikelihoodData]) => {
      return w.bpLog;
    })
    .filter(val => val && val > Math.log10(MinimumChartBP)) as readonly number[];
  const teValues = Object.entries(wildfireLikelihood)
    .map(([key, w]: readonly [string, WildfireLikelihood]) => {
      const wData = w.d;
      return wData.total_housing_units;
    })
    .filter(entry => !!entry) as readonly number[];

  return {
    data,
    bpMin: Math.min(...bpLogValues),
    bpMax: Math.max(...bpLogValues),
    teMin: Math.min(...teValues),
    teMax: Math.max(...teValues)
  };
};

const WildfireLikelihoodComponent = (props: ComponentProps) => {
  const {
    geography,
    geographyLevel,
    comparisonLevel,
    comparisonCountyName,
    comparisonStateName,
    detailPlaceId,
    wildfireLikelihood
  } = props;

  const chart = useMemo(() => {
    const chartData =
      detailPlaceId && geography && "resource" in wildfireLikelihood
        ? getChartData(wildfireLikelihood.resource.wildfireLikelihood, true)
        : null;
    return chartData ? (
      <figure role="group">
        <WildfireLikelihoodChart
          detailPlaceId={detailPlaceId}
          geography={geography}
          minBubbleSize={minBubbleSize}
          maxBubbleSize={maxBubbleSize}
          wildfireLikelihoodChartData={chartData}
          a11yTitle={accessibleChartLabel(
            "wildfire likelihood",
            chartData && chartData.data.length,
            geographyLevel,
            geography,
            comparisonLevel,
            comparisonCountyName,
            comparisonStateName
          )}
        />
        <figcaption>
          <ChartLegends
            colors={wildfireLikelihoodColorBreaks.filter(c => !c.hideInLegend).map(c => c.value)}
            colorsLabel={"Wildfire likelihood"}
            minBubbleSize={6}
            maxBubbleSize={18}
          />
          <AccessibleDataTable
            wildfireLikelihoodData={chartData.data}
            description={accessibleDataTableLabel(
              "Wildfire likelihood",
              chartData.data.length,
              geographyLevel,
              comparisonLevel,
              comparisonCountyName,
              comparisonStateName
            )}
          />
        </figcaption>
      </figure>
    ) : (
      <></>
    );
  }, [
    wildfireLikelihood,
    detailPlaceId,
    geography,
    comparisonCountyName,
    comparisonStateName,
    comparisonLevel,
    geographyLevel
  ]);

  const descriptionText = (
    <>
      <Paragraph size="xlarge">
        Wildfire likelihood is the probability of wildfire burning in any given year. At the
        community level, wildfire likelihood is averaged where housing units occur.
      </Paragraph>
      <Paragraph size="xlarge">
        Communities in all but the lowest classes need to be prepared for wildfire.
      </Paragraph>
    </>
  );

  return (
    <ResponsiveContext.Consumer>
      {size => (
        <>
          <Box pad={{ horizontal: "medium", bottom: "medium" }} className="content-box">
            <Heading level={3}>About wildfire likelihood</Heading>
            {descriptionText}
          </Box>

          <Box pad={{ horizontal: "medium", bottom: "medium" }} className="content-box">
            <Heading level={3}>Compare wildfire likelihood</Heading>
            <Delayed>{chart}</Delayed>
          </Box>
        </>
      )}
    </ResponsiveContext.Consumer>
  );
};

export default WildfireLikelihoodComponent;
